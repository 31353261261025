.linear-wipe {
    text-align: center;
    

    background: linear-gradient(-45deg, 
        #d65c97, 
        #5cd666, 
        #a55cd6, 
        #5c7cd6, 
        #d65c7a, 
        #81d65c, 
        #835cd6, 
        #685cd6, 
        #5c9dd6,
        #5cd670,
        #d6625c,
        #d6835c,
        #d6605c,
        #be5cd6,
        #5c8dd6,
        #95d65c,
        #d69d5c,
        #d65c81,
        #5cd666,
        #d67e5c,
        #64d65c);
    background-size: 500% auto;

    color: #000;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    animation: shine 50s linear infinite;

    @keyframes shine {
        to {
            background-position: 100% center;
        }
    }
}