/* ==========================================================================
Main SCSS File
========================================================================== */

//Imports
@import 'partials/variables';
@import 'partials/font';
@import 'partials/scrolldown-animation';
@import 'partials/rainbow-text-animation';
@import 'partials/blink';
@import 'partials/menu-overlay';
// @import 'partials/contact-form';
@import 'partials/rainbow';
@import 'partials/button';
@import 'partials/spacer';


.is-horizontal-center {
    justify-content: center;
}
 
.section {
    padding: 0;
}

.card {
    box-shadow: none;
}

.dndod-popup {
    position: relative;
    width: 85% !important;
}

.dndod-wrapper {
    z-index: 3000 !important;
}

#imprint-content, #data-policy-content {
    text-align: left;
    line-height: 1em;
    h1 {
        font-size: 16px !important;
    }
    h2 {
        font-size: 14px !important;
    }
    h3 {
        font-size: 12px !important;
    }
    p {
        font-size: 10px !important;
    }
}

figure {
    // margin: 5em;
}

#ribbon {
    width:100%;
    height:100%;
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
  }

.skill-content {
    z-index: 1;
}

#imprint {
    h2 {
        font-size: 20px;
    }
    h3 {
        font-size: 18px;
    }

    p {
        font-size: 15px;
    }
}

#data-policy {
    h2 {
        font-size: 18px;
        line-height: 2em;
    }
    p {
        font-size: 15px;
    }
}

@import 'partials/responsive';
