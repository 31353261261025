/* Fade effect */
.js body {
	opacity: 0;
	transition: opacity 0.3s;		
}

.js body.render {
	opacity: 1;
}

.hidden {
	position: absolute;
	overflow: hidden;
	width: 0;
	height: 0;
	pointer-events: none;
}

main {
    position: absolute;
	width: 100%;
    height: 0;
    z-index: 200;

.content {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	margin: 0 auto;
	pointer-events: none;
}

.content--fixed {
	position: fixed;
	top: 0;
	left: 0;
	display: grid;
	align-content: space-between;
	width: 100%;
	max-width: none;
	min-height: 0;
	height: 100vh;
	padding: 1.5em;
}

.global-menu {
	width: 100vw;
	height: 90vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	pointer-events: none;
    z-index: 2000;
}

.global-menu__item {
	color: #fff;
	font-size: 3.5vmax;
	font-weight: 400;
	opacity: 0;
	transform: translateY(-100%);
	pointer-events: none;
	display: block;
	margin: 0.25em 0;
	transition: transform 0.3s, opacity 0.3s;
	transition-timing-function: ease-in;
}

.global-menu__item--demo-6 {
	transform: translateY(100%);
}

.global-menu__item:hover {
	color: #c14343;
}

.global-menu__item.is-opened {
	opacity: 1;
	transform: translateY(0) rotate(0);
	pointer-events: auto;
	transition-timing-function: ease;
}

/* demo 6 */
.global-menu__item--demo-6.is-opened {
	transition-duration: 0.8s;
}

.global-menu__item--demo-6:nth-of-type(1) {
	transition-delay: 0.25s;
}

.global-menu__item--demo-6.is-opened:nth-of-type(1) {
	transition-delay: 1s;
}

.global-menu__item--demo-6:nth-of-type(2) {
	transition-delay: 0.2s;
}

.global-menu__item--demo-6.is-opened:nth-of-type(2) {
	transition-delay: 1.1s;
}

.global-menu__item--demo-6:nth-of-type(3) {
	transition-delay: 0.15s;
}

.global-menu__item--demo-6.is-opened:nth-of-type(3) {
	transition-delay: 1.2s;
}

.global-menu__item--demo-6:nth-of-type(4) {
	transition-delay: 0.1s;
}

.global-menu__item--demo-6.is-opened:nth-of-type(4) {
	transition-delay: 1.3s;
}

.shape-overlays {
	width: 100vw;
	height: 100vh;
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
}

.shape-overlays.is-opened {
	pointer-events: auto;
}

.shape-overlays__path:nth-of-type(1) {
	fill: url(#gradient1);
}

.shape-overlays__path:nth-of-type(2) {
	fill: url(#gradient2);
}

.shape-overlays__path:nth-of-type(3) {
	fill: url(#gradient3);
}

.shape-overlays__path:nth-of-type(4) {
	fill: #0d1831;
}

@-webkit-keyframes intervalHamburgerBorder {
	0% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	80% {
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
	}
}

@keyframes intervalHamburgerBorder {
	0% {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	80% {
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
	}
}

.hamburger {
	width: 64px;
	height: 64px;
	display: block;
	position: relative;
	cursor: pointer;
	position: absolute;
	top: 2.25em;
	right: 2.25em;
	z-index: 110;
	border-radius: 50%;
	background-color: #120047;
	pointer-events: auto;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

.hamburger::after {
	width: 64px;
	height: 64px;
	box-sizing: border-box;
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	pointer-events: none;
	border: 4px solid #9236f7;
	border-radius: 50%;
	-webkit-animation-duration: 1.2s;
	animation-duration: 2s;
	-webkit-animation-name: intervalHamburgerBorder;
	animation-name: intervalHamburgerBorder;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.hamburger__line {
	width: 28px;
	height: 2px;
	overflow: hidden;
	position: absolute;
	z-index: 10;
}

.hamburger__line-in {
	width: 84px;
	height: 2px;
	position: absolute;
	top: 0;
	left: 0;
}

.hamburger__line-in::before,
.hamburger__line-in::after {
	width: 28px;
	height: 2px;
	content: '';
	display: block;
	position: absolute;
	top: 0;
	background-color: #fff;
}

.hamburger__line-in::before {
	left: -56px;
}

.hamburger__line-in::after {
	left: 0;
}

.hamburger__line--01,
.hamburger__line--02,
.hamburger__line--03,
.hamburger__line--cross01,
.hamburger__line--cross02 {
	left: 18px;
}

.hamburger__line--01 {
	top: 24.6px;
}

.hamburger__line--02,
.hamburger__line--cross01,
.hamburger__line--cross02 {
	top: 31px;
}

.hamburger__line--03 {
	top: 37.4px;
}

.hamburger__line--cross01 {
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.hamburger__line--cross02 {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.hamburger__line {
	-webkit-transition-duration: 0.6s;
	transition-duration: 0.6s;
	-webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger__line-in {
	-webkit-transition-duration: 0.6s;
	transition-duration: 0.6s;
	-webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger__line-in::before,
.hamburger__line-in::after {
	-webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
	-webkit-transition-property: -webkit-transform;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
}

.hamburger__line-in--cross01,
.hamburger__line-in--cross02 {
	-webkit-transform: translateX(-33.3%);
	transform: translateX(-33.3%);
}

.hamburger__line-in--01 {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.hamburger__line-in--02 {
	-webkit-transition-delay: 0.25s;
	transition-delay: 0.25s;
}

.hamburger__line-in--02::before,
.hamburger__line-in--02::after {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
}

.hamburger__line-in--03 {
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.hamburger__line-in--03::before,
.hamburger__line-in--03::after {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.hamburger__line-in--cross01 {
	-webkit-transition-delay: 0.0s;
	transition-delay: 0.0s;
}

.hamburger__line-in--cross02 {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
}

.hamburger__line-in--cross02::before,
.hamburger__line-in--cross02::after {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.hamburger.is-opened-navi .hamburger__line-in--01,
.hamburger.is-opened-navi .hamburger__line-in--02,
.hamburger.is-opened-navi .hamburger__line-in--03 {
	-webkit-transform: translateX(33.3%);
	transform: translateX(33.3%);
}

.hamburger.is-opened-navi .hamburger__line-in--cross01,
.hamburger.is-opened-navi .hamburger__line-in--cross02 {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

.hamburger.is-opened-navi .hamburger__line-in--01 {
	-webkit-transition-delay: 0s;
	transition-delay: 0s;
}

.hamburger.is-opened-navi .hamburger__line-in--02 {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
}

.hamburger.is-opened-navi .hamburger__line-in--03 {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.hamburger.is-opened-navi .hamburger__line-in--cross01 {
	-webkit-transition-delay: 0.25s;
	transition-delay: 0.25s;
}

.hamburger.is-opened-navi .hamburger__line-in--cross02 {
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.hamburger:hover .hamburger__line-in::before,
.hamburger:hover .hamburger__line-in::after {
	-webkit-transform: translateX(200%);
	transform: translateX(200%);
}

.hamburger:hover .hamburger__line-in--01::before,
.hamburger:hover .hamburger__line-in--01::after,
.hamburger:hover .hamburger__line-in--02::before,
.hamburger:hover .hamburger__line-in--02::after,
.hamburger:hover .hamburger__line-in--03::before,
.hamburger:hover .hamburger__line-in--03::after {
	-webkit-transition-duration: 1s;
	transition-duration: 1s;
}

.hamburger:hover .hamburger__line-in--cross01::before,
.hamburger:hover .hamburger__line-in--cross01::after,
.hamburger:hover .hamburger__line-in--cross02::before,
.hamburger:hover .hamburger__line-in--cross02::after {
	-webkit-transition-duration: 0s;
	transition-duration: 0s;
}

.hamburger.is-opened-navi:hover .hamburger__line-in--cross01::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross01::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross02::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--cross02::after {
	-webkit-transition-duration: 1s;
	transition-duration: 1s;
}

.hamburger.is-opened-navi:hover .hamburger__line-in--01::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--01::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--02::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--02::after,
.hamburger.is-opened-navi:hover .hamburger__line-in--03::before,
.hamburger.is-opened-navi:hover .hamburger__line-in--03::after {
	-webkit-transition-duration: 0s;
	transition-duration: 0s;
}


@media screen and (min-width: 55em) {
	.icon--keyboard {
		position: absolute;
		right: 0.55em;
		bottom: -30%;
		display: block;
		width: 54px;
		height: 46px;
		fill: #110046;
	}
	.demos {
		display: flex;
		padding-right: 80px;
		justify-self: end;
	}
	.demo {
		display: block;
		width: 17px;
		height: 17px;
		margin: 0 4px;
		border-radius: 50%;
		background: #110046;
	}
	a.demo--current {
		background: #e83779;
	}
	.demo span {
		position: absolute;
		line-height: 1;
		right: 100%;
		display: none;
		margin: 0 1em 0 0;
	}
	.demo--current span {
		display: block;
	}
}

}

#dynamic-text-container-imprint, #dynamic-text-container-data-policy {
	display: none;
}