.contact-btn {
    text-align: center;
}

.contact-btn a {
    position: relative;
    top: 50px;
}

.btn-hover {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    padding: 15px 105px;
    text-align:center;
    border: none;
    background-size: 300% 100%;
    border-radius: 50px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    @media screen and (max-width: 768px) {
        padding: 15px 80px;
    }
}

.btn-hover:hover {
    color: #fff;
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.btn-hover:focus {
    outline: none;
}

.btn-hover.color-1 {
    background-image: linear-gradient(to right, #667eea, #764ba2, #6B8DD6, #8E37D7);
    box-shadow: 0 4px 15px 0 rgba(116, 79, 168, 0.75);
}

.site-info {
    a {
        border: 1px solid #4a4a4a;
        padding: 5px 10px;
        color: #4a4a4a;
        text-decoration: none;
        font-size: 10px;
        cursor: pointer;
        &:hover {
            
        }
    }
}