@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Arvo');

// Designer Font
@import url('https://fonts.googleapis.com/css?family=Kaushan+Script');
// Developer Font
@import url('https://fonts.googleapis.com/css?family=Cousine');

* {
    font-family: 'Nunito', sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // font-family: 'Poppins', sans-serif;
    // font-family: 'Arvo', serif;
}

h1 {
    font-size: 4em !important;
    @media screen and (max-width: 800px) {
        font-size: 3em !important;
    }
    // @media screen and (max-width: 650px) {
    //     font-size: 2em !important;
    // }
    // @media screen and (max-width: 550px) {
    //     font-size: 2em !important;
    // }
}

h2 {
    font-size: 2em !important;
    @media screen and (max-width: 768px) {
        font-size: 1.5em !important;
    }
    &.about-me-h2-heading, &.technologies-h2-heading, &.hire-me-h2-heading {
        margin-bottom: 0;
        font-size: 3em !important;
        font-weight: bold;
        @media screen and (max-width: 768px) {
            font-size: 3em !important;
        }
        @media screen and (max-width: 375px) {
            font-size: 2em !important;
        }
        // @media screen and (max-width: 650px) {
        //     font-size: 1.5em !important;
        // }
        // @media screen and (max-width: 550px) {
        //     font-size: 1em !important;
        // }
    }

    &.contact-h2-heading {
        text-align: center;
        font-size: 4em !important;
        font-weight: bold;
        margin-bottom: 1.5em;
    }
    &.technologies-h2 {
        text-align: center;
        font-size: 4em !important;
        font-weight: bold;
        margin-bottom: 1.5em;
    }
}

p {
    font-size: 2em;
    @media screen and (max-width: 880px) {
        font-size: 1.5em !important;
    }
    @media screen and (max-width: 370px) {
        font-size: 1.2em !important;
    }
    // @media screen and (max-width: 768px) {
    //     font-size: 2em !important;
    // }
    // @media screen and (max-width: 550px) {
    //     font-size: 1em !important;
    // }

    &.about-me-left-p {
        font-size: 1em;
        text-align: center;
        margin: 3em 0;
        @media screen and (max-width: 880px) {
            font-size: 0.8em !important;
        }
        @media screen and (max-width: 768px) {
            display: none;
        }
    }
    &.thank-you {
        margin: 2em 0;
    }
}

figure {
    text-align: center;
}

#svg {
    width: 100%;
    height: auto;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

#contact-img {
    width: 100%;
    height: auto;
    @media screen and (max-width: 768px) {
        display: none;
    }
}

#technologies {
    width: 100%;
    height: auto;
}

strong {
    &.about-me-strong {
        // font-size: 1.25em;
    }
    &.about-me-and span {
        font-family: 'Kaushan Script', cursive;
        font-weight: 100;
    }
    &.about-me-designer > span {
        font-family: 'Kaushan Script';
        // font-size: 1.25em;
    }
    &.about-me-developer span {
        // font-family: 'Cousine', monospace;
        font-family: 'Kaushan Script', cursive;
        // font-size: 1.25em;
    }
}

figure {
    img.thank-you-img {
        margin: 1em;
    }
}

span {
    &.thank-you {
        margin: 1em 0;
    }
}

input {
    font-family: 'Nunito', sans-serif;
}

.card {
    > p.card-content {
        font-size: 10px !important;
    }
}
